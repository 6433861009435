<template>
  <div class="sendsform">
    <div class="search-list">
      <el-button type="primary" @click="add">添加</el-button>
      <el-button type="primary" :disabled="item&&item.status==1" @click="seton">启用</el-button>
      <el-button type="danger" :disabled="item&&item.status==2" @click="setoff">禁用</el-button>
      <el-button type="danger" @click="del">删除</el-button>
    </div>
    <div class="table">
      <el-table
        :data="list"
        style="width: 100%"
        v-loading="dataListLoading"
        @selection-change="selectionChangeHandle"
      >
        <el-table-column type="selection" width="50"></el-table-column>
        <el-table-column prop="sendNodeName" label="发送节点"></el-table-column>
        <el-table-column prop="status" label="状态">
          <template slot-scope="scope">
            <div v-if="scope.row.status==1">启用</div>
            <div v-else-if="scope.row.status==2">禁用</div>
          </template>
        </el-table-column>
        <el-table-column prop="context" label="备注"></el-table-column>
        <el-table-column prop="createName" label="添加人"></el-table-column>
        <el-table-column prop="createTime" label="创建时间"></el-table-column>
        <el-table-column label="操作" header-align="right" align="right" fixed="right">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="goToInfo(scope.row)">详情</el-button>
            <el-button type="text" size="small" @click="goToEdit(scope.row)">修改</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="sizeChangeHandle"
        @current-change="currentChangeHandle"
        :current-page="pageNo"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="pageSize"
        :total="totalPage"
        layout="total, sizes, prev, pager, next, jumper"
      ></el-pagination>
    </div>
  </div>
</template>
<script>
import Qs from "qs";
export default {
  name: "sendsform",
  data() {
    return {
      list: [{}],
      dataListLoading: true, //列表loading
      dataListSelections: [], //选中的列
      pageNo: 1,
      pageSize: 10,
      totalPage: 0, //总页数
      typeList: [],
      userList: [],
      item: "",
    };
  },
  async mounted() {
    await this.getTypeList();
    await this.getUserList();
    await this.getList();
  },
  methods: {
    async getUserList() {
      const { data: res } = await this.$httpAes({
        url: this.$httpAes.adornUrl("/vfRelationInfo/list"),
        method: "post",
        data: {
          stringParam1: JSON.parse(localStorage.getItem("currentTeam")).id,
          // status:1,
          stringParam4: "be02338588184f948123cd51612f1fcc",
          stringParam6: "2023-07-26",
          stringParam7: "2023-07-29",
          intParam1: 999,
        },
      });
      this.userList = res.data;
    },
    async getTypeList() {
      const { data: res } = await this.$httpAes({
        url: this.$httpAes.adornUrl("/dict/list"),
        method: "post",
		data: {
		  stringParam1: "发送节点",
		},
       
      });
      this.typeList = res.data;
    },
    async getList() {
      let teamsId = "";
      if (localStorage.currentTeam) {
        teamsId = JSON.parse(localStorage.currentTeam).id;
      }
      const { data: res } = await this.$httpAes({
        url: this.$httpAes.adornUrl("/systemSends/list"),
        method: "post",
        params: {
          stringParam1: teamsId,
          intParam1: this.pageSize,
          intParam2: this.pageNo,
        },
      });
      // console.log(res,'res')
      res.data.forEach((v) => {
		  // 使用示例
		
		 v.createTime = this.timestampToDateTime(v.createTime);

        this.typeList.forEach((v2) => {
          if (v.sendNode == v2.value) {
            v.sendNodeName = v2.name;
          }
        });
        this.userList.forEach((v3) => {
          if (v.createBy == v3.id) {
            v.createByName = v3.docterName;
          }
        });
      });
      this.list = res.data;
      this.totalPage = res.totalCount;
      this.dataListLoading = false;
    },
 timestampToDateTime(timestamp) {
  var date = new Date(timestamp);
  var year = date.getFullYear();
  var month = ("0" + (date.getMonth() + 1)).slice(-2); // getMonth() 返回0-11，所以需要加1
  var day = ("0" + date.getDate()).slice(-2);
  var hours = ("0" + date.getHours()).slice(-2);
  var minutes = ("0" + date.getMinutes()).slice(-2);
  var seconds = ("0" + date.getSeconds()).slice(-2);

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
},


    async add() {
      this.$router.push("/sendsform/add");
    },
    goToInfo(row) {
      this.$router.push({ path: "/sendsform/info", query: { edit: false } });
      sessionStorage.setItem("formItem", JSON.stringify(row));
    },
    goToEdit(row) {
      this.$router.push({ path: "/sendsform/edit", query: { edit: true } });
      sessionStorage.setItem("formItem", JSON.stringify(row));
    },
    async seton() {
      this.item.status = 1;
      const { data: res } = await this.$httpAes({
        url: this.$httpAes.adornUrl("/systemSends/update"),
        method: "post",
        data: this.item,
      });
      if (res.status) {
        this.$message({
          message: res.msg,
          type: "success",
          duration: 1500,
          onClose: () => {
            // this.$router.back()
          },
        });
      } else {
        this.$message.error(res.msg);
      }
    },
    async setoff() {
      this.$confirm(
        `当前正在进行禁用操作
        \n
        禁用后不会再自动发送，请确认`,
        "禁用",
        {
          confirmButtonText: "确定",
          cancelButtonClass: "btn-custom-cancel",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(async () => {
          this.item.status = 2;
          const { data: res } = await this.$httpAes({
            url: this.$httpAes.adornUrl("/systemSends/update"),
            method: "post",
            data: this.item,
          });
          if (res.status) {
            this.$message({
              message: res.msg,
              type: "success",
              duration: 1500,
              onClose: () => {
                // this.$router.back()
              },
            });
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch(() => {});
    },
    async del(row) {
      // console.log(row,'row');
      // const id = row.id
      this.$confirm("确定进行[删除表单]操作", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const ids = [];
          this.dataListSelections.forEach((v) => {
            ids.push(v.id);
          });
          const id = ids.join(`,`);

          const { data: res } = await this.$http({
            url: this.$http.adornUrl(`/systemSends/delete?ids=${id}`),
            method: "post",
          });
          console.log(res);
          if (res.status) {
            this.$message.success("删除表单成功");
            this.getList();
          }
        })
        .catch(() => {});
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val;
      this.pageNo = 1;
      this.getList();
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageNo = val;
      this.getList();
    },
    // 多选
    selectionChangeHandle(val) {
      this.dataListSelections = val;
      console.log(val);
      this.item = val[0];
      console.log(this.item, "item");
    },
  },
};
</script>

<style lang="scss" scoped>
.sendsform {
}
</style>